<template>
<div class="bg-white p-2 rounded-md mt-2">
  <DataTable :value="data.rows"
             class="p-datatable-customers p-datatable-sm text-sm"
             showGridlines
             dataKey="id"
             v-model:filters="filters1"
             filterDisplay="menu"
             responsiveLayout="scroll"
             removableSort
  >
    <template #empty>
      No hay datos para mostrar
    </template>
    <Column headerStyle="width: 3rem" style="text-align: center; justify-content: center;" field="id" header="Id">
      <template #body="{data}">
        {{ data.id }}
      </template>
    </Column>

    <Column field="observacion" header="Observaciones" style="min-width:10rem">
      <template #body="{data}">
        {{ data.observacion }}
      </template>
    </Column>

    <!-- <Column field="path" header="Adjunto">
      <template #body="{data}">
        <template v-if="data.path">
          <Button :label="data.path.split(',')[1]"
                  class="p-button-link"
                  style="font-size: 0.75rem"
                  @click="verPdf(data.path.split(',')[0])"
          />
        </template>
        <template v-else>
          No tiene
        </template>
      </template>
    </Column> -->

    <Column field="createdAt" header="Fecha Notificacion" style="min-width:10rem">
      <template #body="{data}">
        {{ $h.formatDate(data.fechaNotificacion, 'YYYY-MM-DD HH:mm:ss') }}
      </template>
    </Column>

    <Column field="createdAt" header="Fecha Vencimiento" style="min-width:10rem">
      <template #body="{data}">
        {{ $h.formatDate(data.fechaVencimiento, 'YYYY-MM-DD HH:mm:ss') }}
      </template>
    </Column>

    <Column field="createdBy" header="Creado Por" style="min-width:10rem" :showFilterMatchModes="false">
      <template #body="{data}">
      {{ data.createdByName }}
        {{ data.createdByNameSancion.fullName }}
      </template>
      <template #filter="{filterModel}">
        <div class="mb-3 font-bold">Seleccione</div>
        <Dropdown v-model="filterModel.value" :options="usuarios" optionLabel="fullName" optionValue="id" placeholder="Cualquiera" :filter="true" />
      </template>
    </Column>
    <Column v-if="$can('pharmasan.administrativa.juridica.tutelas.access')" header="Acciones" style="width: 10rem">
      <template #body="{data}">
        <div class="flex gap-4">
          <Button icon="pi pi-eye" class="p-button-rounded p-button-help" @click="openModal(data.id)" />
          <Button icon="pi pi-pencil" class="p-button-rounded p-button-warning" @click="onEditar(data)" />
        </div>
      </template>
    </Column>
    <Dialog header="Adjuntos" v-model:visible="displayModal" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '30vw'}" :modal="true">
    <div v-for="(adjunto, i) in desacato" :key="i">
      <div class="flex justify-between">
        <div>
          <p>
            {{ adjunto.fileName }}
          </p>
        </div>
        <div>
          <button @click="verPdf(adjunto.urlPath)">
            <i class="pi pi-eye text-blue-600"></i>
          </button>
        </div>
      </div>
    </div>
    <template #footer>
        <Button label="OK" @click="closeModal" autofocus />
    </template>
  </Dialog>
  </DataTable>
  <Paginator v-model:first="paginator.offset"
             :rows="paginator.limit"
             :totalRecords="data.count"
             :rowsPerPageOptions="[2,10,20,30]"
             @page="onPage($event)"
  />
</div>
</template>

<script>
import desacatosStore from '../../../../../../store/desacatos.store'
import { computed, onMounted, ref, watch } from 'vue'
import Swal from 'sweetalert2'
import { useRouter, useRoute } from 'vue-router'
import PdfService from '@/apps/pharmasan/juridica/procesosJuridicos/services/pdf.service'
import UserSerivce from '@/apps/pharmasan/juridica/procesosJuridicos/services/usuarios.service'

export default {
  name: 'TablaDesacatos',
  setup () {
    const router = useRouter()
    const route = useRoute()
    const _PdfService = new PdfService()
    const _UserService = new UserSerivce()
    const data = computed(() => desacatosStore.getters.desacatos)
    const desacato = computed(() => desacatosStore.getters.desacato.paths)
    const usuarios = ref([])
    const displayModal = ref(false)

    const verPdf = (path) => {
      _PdfService.ver({ path })
    }

    const openModal = async (id) => {
      await desacatosStore.dispatch('findDesacato', id)
      displayModal.value = true
    }

    const closeModal = () => {
      displayModal.value = false
      desacato.value = []
    }

    const paginator = ref({
      offset: 0,
      page: 0,
      limit: 10
    })

    const filters1 = ref({
    })

    const clearFilter1 = () => {
      filters1.value = {
      }
    }
    const onEditar = (data) => {
      router.push({ name: 'pharmasan.administrativa.juridica.sanciones.editar', params: { id: data.id }, query: { idTutela: route.params.id } })
    }
    const onPage = ({ first, page, pageCount, rows }) => {
      paginator.value.offset = first
      paginator.value.page = page
      desacatosStore.dispatch('desacatosTutela', route.params.id, {
        paginator: paginator.value,
        filters: filters1.value
      })
    }

    const remove = ({ id }) => {
      Swal.fire({
        title: 'Esta seguro?',
        text: 'Si se elimina este juzgado no se puede recuperar',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, eliminar!'
      }).then((result) => {
        if (result.isConfirmed) {
          desacatosStore.dispatch('removeDesacato', id).then(async () => {
            await desacatosStore.dispatch('desacatosTutela', route.params.id, {
              paginator: paginator.value,
              filters: filters1.value
            })
            await Swal.fire(
              'Eliminado!',
              'El Desacato fue eliminado con exito.',
              'success'
            )
          })
        }
      })
    }

    watch(filters1, (a, b) => {
      paginator.value.offset = 0
      paginator.value.page = 0
      paginator.value.limit = 10
      desacatosStore.dispatch('desacatosTutela', route.params.id, {
        paginator: paginator.value,
        filters: filters1.value
      })
    })

    onMounted(async () => {
      await desacatosStore.dispatch('desacatosTutela', route.params.id, {
        paginator: paginator.value,
        filters: filters1.value
      })
      await _UserService.getAll().then(({ data }) => {
        usuarios.value = data
      })
    })

    return {
      filters1,
      paginator,
      clearFilter1,
      onPage,
      remove,
      onEditar,
      verPdf,
      usuarios,
      openModal,
      data,
      displayModal,
      desacato,
      closeModal
    }
  }
}
</script>

<style scoped>
  ::v-deep(.pi) {
    font-size: 0.8rem;
}
  ::v-deep(.p-column-header-content) {
    justify-content: center;
}
</style>

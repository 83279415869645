import { createStore } from 'vuex'
import DesacatosService from '../services/desacatos.service'
const _DesacatosService = new DesacatosService()

const storeDesacatos = {
  state: {
    _desacatos: [],
    _desacato: {}
  },
  getters: {
    desacatos: state => state._desacatos,
    desacato: state => state._desacato
  },
  mutations: {
    setDesacatos (state, payload) {
      state._desacatos = payload
    },
    setDesacato (state, payload) {
      state._desacato = payload
    },
    setUpdateDesacato (state, payload) {
      state._desacato.observacion = payload.observacion
      state._desacato.incidenteId = payload.incidenteId
      state._desacato.tipo = payload.tipo
      state._desacato.path = payload.path
      state._desacato.fechaNotificacion = payload.fechaNotificacion
    },
    setDefault (state) {
      state._desacato = {}
    }
  },
  actions: {
    getPaginate ({ commit }, payload) {
      _DesacatosService.paginate(payload).then(({ data }) => {
        commit('setDesacatos', data)
      })
    },
    createDesacato ({ commit }, payload) {
        return _DesacatosService.save(payload).then((response) => {
            return response
        })
    },
    findDesacato ({ commit }, id) {
      return new Promise((resolve, reject) => {
        _DesacatosService.find(id).then(({ data }) => {
          resolve(data)
          this.state._desacato = data
        })
      })
    },
    updateDesacato ({ getters }, id) {
      return new Promise((resolve, reject) => {
        _DesacatosService.edit(getters.desacato, id).then(({ data }) => {
          resolve(data)
        })
      })
    },
    removeDesacato ({ commit }, id) {
      return _DesacatosService.remove(id).then((response) => {
        return response
      })
    },
    desacatosTutela ({ commit }, id, payload) {
      return _DesacatosService.desacatosTutela(id, payload).then(({ data }) => {
        commit('setDesacatos', data)
      })
    }
  }
}
const store = createStore(storeDesacatos)
export default {
  namespaced: true,
  ...store
}
